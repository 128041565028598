import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
    ICountRequest,
    ICount,
    IDeviceParameterDetails,
    IParameters,
    IStatistic,
    IStatistics,
    IModel,
} from 'src/app/statistics/interfaces/statistics.model';

@Injectable({
    providedIn: 'root',
})
export class ManageFactoryService {
    token = sessionStorage.getItem('token');
    urlBase = `${environment.apiUrl}/api/v1`;
    urlBaseV2 = `${environment.apiUrl}/api/v2`;

    constructor(private http: HttpClient) {}

    alerts = {
        getValueNames: (modelId: number) =>
            this.http.get(
                `${this.urlBase}/clientUser/read-only/serviceAlerts/getValueNames/${modelId}`,
            ),
    };

    statistics = {
        read: (id: number) =>
            this.http.get<IStatistic>(`${this.urlBaseV2}/statistics/${id}`),
        readAll: (options: { params: HttpParams }) =>
            this.http.get<IStatistic[]>(
                `${this.urlBaseV2}/statistics`,
                options,
            ),
        delete: (id: number) =>
            this.http.delete(`${this.urlBaseV2}/statistics/${id}`),
        create: (data: IStatistic) =>
            this.http.post(`${this.urlBaseV2}/statistics`, data),
        update: (data: IStatistic) =>
            this.http.put(`${this.urlBaseV2}/statistics/${data.id}`, data),
        getParameters: () =>
            this.http.get<IParameters[]>(
                `${this.urlBaseV2}/statistics/parameters`,
            ),
        getTemplates: () =>
            this.http.get<IStatistic[]>(
                `${this.urlBaseV2}/statistics/templates`,
            ),
        generate: (query: HttpParams) =>
            this.http.get<IStatistics[]>(
                `${this.urlBaseV2}/statistics/generateStatistic?${query}`,
            ),
        getDeviceData: (query: HttpParams) =>
            this.http.get<IDeviceParameterDetails>(
                `${this.urlBaseV2}/statistics/getParametersData?${query}`,
            ),
        generateCount: (data: ICountRequest) =>
            this.http.post<ICount[]>(
                `${this.urlBaseV2}/statistics/getCount`,
                data,
            ),
        getModels: (query: HttpParams) =>
            this.http.get<IModel[]>(
                `${this.urlBaseV2}/statistics/models?${query}`,
            ),
    };
}
